<template>
	<div>
		<Row justify="center">
			<Col :xl="12">
			<Row class-name="pad-b24" align="middle">
				<Col span="5">
				</Col>
				<Col span="5">开关</Col>
				<Col span="7">频率（分钟）</Col>
				<Col span="7">操作</Col>
			</Row>
			<Row class-name="pad-b24" align="middle">
				<Col span="5">定位间隔时间</Col>
				<Col span="5">
				<i-Switch v-model="control_data.data.location_status" :true-value="1" :false-value="0"
					:disabled="device_m_id == 7"></i-Switch>
				</Col>
				<Col span="7">
				<InputNumber v-if="control_data.data.location_status" :min="5" :max="1000" class="w150"
					v-model="control_data.data.locationtimeinterval" :active-change="false" :precision="0">
				</InputNumber>
				</Col>
				<Col span="7">
				<Button type="primary" @click="submitCommand(128)" style="margin-right: 10px;">立即定位</Button>
				<Button type="primary" @click="setTings('location_status','locationtimeinterval')">设置</Button>
				</Col>
			</Row>
			<Row class-name="pad-b24" align="middle" v-if="!(device_m_id == 11 && device_d_type == 5)">
				<Col span="5">{{ device_m_id == 6 ? '心率/血压/血氧' : '心率' }}间隔时间</Col>
				<Col span="5">
				<i-Switch v-model="control_data.data.heart_status" :true-value="1" :false-value="0"></i-Switch>
				</Col>
				<Col span="7">
				<InputNumber v-if="control_data.data.heart_status" :min="5" :max="1000" class="w150"
					v-model="control_data.data.heartratetimeinterval" :active-change="false" :precision="0">
				</InputNumber>
				</Col>
				<Col span="7">
				<Button type="primary" @click="submitCommand(127)" style="margin-right: 10px;">立即测量</Button>
				<Button type="primary" @click="setTings('heart_status','heartratetimeinterval')">设置</Button>
				</Col>
			</Row>
			<Row class-name="pad-b24" align="middle" v-if="device_m_id != 7 && device_m_id != 6 && !(device_m_id == 11 && device_d_type == 5)">
				<Col span="5">血压间隔时间</Col>
				<Col span="5">
				<i-Switch v-model="control_data.data.bpi_status" :true-value="1" :false-value="0"></i-Switch>
				</Col>
				<Col span="7">
				<InputNumber v-if="control_data.data.bpi_status" :min="5" :max="1000" class="w150"
					v-model="control_data.data.bpi" :active-change="false" :precision="0"></InputNumber>
				</Col>
				<Col span="7">
				<Button type="primary" @click="submitCommand(120)" style="margin-right: 10px;">立即测量</Button>
				<Button type="primary" @click="setTings('bpi_status','bpi')">设置</Button>
				</Col>
			</Row>
			<Row class-name="pad-b24" align="middle" v-if="device_m_id !== 10 && !(device_m_id == 11 && device_d_type == 5)">
				<Col span="5">体温间隔时间</Col>
				<Col span="5">
				<i-Switch v-model="control_data.data.tpi_status" :true-value="1" :false-value="0"></i-Switch>
				</Col>
				<Col span="7">
				<InputNumber v-if="control_data.data.tpi_status" :min="5" :max="1000" class="w150"
					v-model="control_data.data.tpi" :active-change="false" :precision="0"></InputNumber>
				</Col>
				<Col span="7">
				<Button type="primary" @click="submitCommand(117)" style="margin-right: 10px;">立即测量</Button>
				<Button type="primary" @click="setTings('tpi_status','tpi')">设置</Button>
				</Col>
			</Row>
			<Row class-name="pad-b24" align="middle" v-if="device_m_id != 4 && device_m_id != 6 && !(device_m_id == 11 && device_d_type == 5)">
				<Col span="5">血氧间隔时间</Col>
				<Col span="5">
				<i-Switch v-model="control_data.data.oxi_status" :true-value="1" :false-value="0"></i-Switch>
				</Col>
				<Col span="7">
				<InputNumber v-if="control_data.data.oxi_status" :min="5" :max="1000" class="w150"
					v-model="control_data.data.oxi" :active-change="false" :precision="0"></InputNumber>
				</Col>
				<Col span="7">
				<Button type="primary" @click="submitCommand(119)" style="margin-right: 10px;">立即测量</Button>
				<Button type="primary" @click="setTings('oxi_status','oxi')">设置</Button>
				</Col>
			</Row>
			</Col>
		</Row>
	</div>
</template>

<script>
	var formData = {
		location_status: 1,
		locationtimeinterval: 60,
		heart_status: 0,
		heartratetimeinterval: 60,
		bpi_status: 0,
		bpi: 60,
		tpi_status: 0,
		tpi: 60,
		oxi_status: 0,
		oxi: 60,
	}
	export default {
		name: 'EquipmentControl',
		data() {
			return {
				//设备控制数据
				control_data: {
					device_type: 0, //0：手表 1：血压计 2：血糖仪
					data: formData
				},
			}
		},
		props: {
			path: {
				type: String,
				default: '/adm'
			},
			device_imei: {
				type: String,
				default: ''
			},
			device_m_id: {
				type: [Number, String],
				default: 0
			},
			device_d_type: {
				type: [String,Number],
				default: 0
			}
		},
		methods: {
			//获取设备控制信息
			init() {
				var _this = this;
				this.control_data.data = JSON.parse(JSON.stringify(formData))
				var data = {
					data: JSON.parse(JSON.stringify(this.control_data.data))
				}
				data.deviceid = this.device_imei
				this.requestApi(this.path + '/get_device_settings', data).then((res) => {
					if (res.status) {
						_this.control_data.data = res.data;
					}
				});
			},
			//设置设备控制信息
			setTings(key, name) {
				var _this = this;
				if (!this.control_data.data[key] && this.device_m_id != 7) return this.alertErr('请打开频率开关')
				var data = {
					deviceid: this.device_imei,
				};
				this.$set(data, key, this.control_data.data[key]);
				this.$set(data, name, this.control_data.data[name]);
				this.requestApi(this.path + '/set_device_settings', data).then((res) => {
					if (res.status) {
						_this.alertSucc(res.msg)
						_this.init()
					} else {
						_this.alertErr(res.msg)
					}
				});
			},
			//发送指令（数据）到设备
			submitCommand(value) {
				var _this = this;
				var data = {
					deviceid: this.device_imei,
					command: value
				};
				this.requestApi(this.path + '/submit_device_command', data).then(function(res) {
					if (res.status) {
						_this.alertSucc(res.msg)
					} else {
						_this.alertErr(res.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.pad-b24 {
		padding-bottom: 24px;
	}

	.w150 {
		width: 150px;
	}
</style>
