<template>
	<div style="display: flex;">
		<div class="footprint-left width-50" style="margin-right: 10px;">
			<Table :data="footprint_data" :columns="footprint_columns" border :loading="table_loading"></Table>
			<Page style="text-align: right;margin-top: 10px;" v-model="search.page" :page-size="search.pageSize"
				:total="search.total" :page-size-opts="[10,15,20]" show-sizer show-total @on-change="page_change"
				@on-page-size-change="page_size_change"></Page>
		</div>
		<div class="footprint-right width-50">
			<Map ref="map"></Map>
		</div>
	</div>
</template>

<script>
	import Map from '@/views/device/device_history.vue';
	export default {
		name: 'HistoricalFootprint',
		components: {
			Map
		},
		data() {
			return {
				table_loading: false, // 表格加载状态
				search: {
					page: 1, //当前页码
					total: 0, //数据总数
					pageSize: 10 //每页条数
				},
				// 足迹数据
				footprint_data: [],
				// 足迹表头
				footprint_columns: [{
						title: '序号',
						render: (h, param) => {
							return h('div', param.index + 1)
						},
						align: 'center',
						width: 100
					},
					{
						title: '时间',
						key: 'create_date',
						align: 'center'
					},
					{
						title: '位置',
						key: 'addr',
						align: 'center'
					},
				],
			}
		},
		props: {
			path: {
				type: String,
				default: '/adm'
			},
			device_imei: {
				type: String,
				default: ''
			},
			device_m_id: {
				type: [Number, String],
				default: 0
			}
		},
		methods: {
			//获取历史足迹表格数据
			init() {
				var _this = this;
				let data = this.search
				data.imei = this.device_imei
				this.table_loading = true
				this.requestApi(this.path + '/get_footprint_data', {
					data
				}).then((res) => {
					_this.table_loading = false
					if (res.code == 200) {
						_this.footprint_data = res.data.data;
						_this.$refs['map'].initMap(res.data.data);
						_this.search.total = res.data.total
					} else {
						_this.alertErr(res.msg)
					}
				});
			},
			// 切换页码
			page_change(page) {
				this.search.page = page;
				this.init();
			},
			// 切换每页条数
			page_size_change(pageSize) {
				this.search.pageSize = pageSize;
				this.init();
			}
		}
	}
</script>

<style scoped>
	.width-50 {
		width: 50%;
	}
</style>
