<template>
	<div class="store-body">
		<Modal v-model="show" title="选择商家" @on-ok="confirm_store()" :width="50" @on-cancel="modal_cancel">
			<div class="search-form">
				<div class="from-item">
					<div>商家名称：</div>
					<Input style="width: 200px;" v-model="search_form.name" placeholder="请输入">
						<Button slot="append" type="primary" @click="init()">搜索</Button>
					</Input>
				</div>
			</div>
			<Table :data="data" :columns="columns" :loading="loading" style="margin-top: 10px;">
				<template slot="type" slot-scope="{row}">
					<Tag :color="row.type == 1?'primary':'success'">{{ row.type == 1?'私有化客户':'Saas客户' }}</Tag>
				</template>
				<template slot="operation" slot-scope="{row}">
					<Button :type="row.store_id == store_info.store_id?'primary':'default'" @click="sel_store(row)">选择</Button>
				</template>
			</Table>
			<Page style="margin-top: 10px;text-align: right;" v-model="search_form.page" :total="search_form.total" :page-size="search_form.pageSize" :page-size-opts="[10,15,20]" show-total show-sizer transfer @on-change="page_change" @on-page-size-change="pageSize_change"></Page>
		</Modal>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false, //模拟态是否关闭
				//查询条件
				search_form: {
					name: null ,//商家名称
					page: 1,	//当前页码
					pageSize: 10, //每页条数
					total: 0	//总数
				},
				//选择的商户信息
				store_info: {
					name: null, //商户名称
					store_id: null //商户ID
				},
				//表格加载
				loading: false,
				data: [{
					name: '寻疗', //商户名称
					store_id: 13, //商户ID
					create_date: '2022-01-01', //创建时间
					type: 0 // 0：saas客户 1：私有化客户
				}],
				columns: [{
					title: '名称',
					key: 'name',
					align: 'center'
				}, {
					title: '创建时间',
					key: 'create_date',
					align: 'center'
				}, {
					title: '客户类型',
					slot: 'type',
					align: 'center'
				}, {
					title: '操作',
					slot: 'operation',
					align: 'center'
				}]
			}
		},
		methods: {
			//获取表格数据
			init() {
				let _this = this
				this.loading = true
				this.requestApi('/adm/get_authorization_store', {
					search_form: this.search_form
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.loading = false
							_this.data = res.data.data
							_this.search_form.total = res.data.total
						} else {
							_this.alertErr(res.msg)
						}
					}
				)
			},
			//打开模拟态
			open_modal() {
				this.show = true
				this.init()
			},
			//选择商家
			sel_store(item) {
				this.store_info = item
			},
			//确认选择
			confirm_store() {
				this.$emit('sel-succ', this.store_info)
				this.store_info = {
					store_id: null,
					name: null
				}
			},
			//切换页码
			page_change(page) {
				this.search_form.page = page
				this.init()
			},
			//切换每页条数
			pageSize_change(pageSize) {
				this.search_form.pageSize = pageSize
				this.init()
			},
			//关闭模拟态
			modal_cancel() {
				this.store_info = {
					store_id: null,
					name: null
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.from-item {
		display: flex;
		align-items: center;
	}
</style>
