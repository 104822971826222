<template>
	<div class="map-body">
		<Modal :title="oper_type?'新增':'修改'" v-model="show" :loading="modal_loading" @on-ok="confirm_modal">
			<Form :label-width="100" label-colon inline>
				<FormItem label="选择商家" v-if="column_type == 0">
					<div slot="label">
						<i class="iconfont icon-bitian"></i>
						<span>选择商家</span>
					</div>
					<Input class="width-250" v-model="data.store_name" disabled placeholder="请选择">
					<Button type="primary" slot="append" @click="$refs['SelStore'].open_modal()">选择商家</Button>
					</Input>
				</FormItem>
				<FormItem label="围栏名称">
					<div slot="label">
						<i class="iconfont icon-bitian"></i>
						<span>围栏名称</span>
					</div>
					<Input class="width-250" placeholder="请输入" v-model="data.name"></Input>
				</FormItem>
				<FormItem label="围栏类型">
					<div slot="label">
						<i class="iconfont icon-bitian"></i>
						<span>围栏类型</span>
					</div>
					<RadioGroup type="button" v-model="data.type" @on-change="() => {this.data.longitude = null;this.data.latitude = null;this.data.radius = null}" button-style="solid">
						<Radio :label="0">圆形</Radio>
						<!-- <Radio :label="1">多边形</Radio> -->
						<!-- <Radio :label="2">行政区域</Radio> -->
					</RadioGroup>
				</FormItem>
				<FormItem label="经度">
					<div slot="label">
						<i class="iconfont icon-bitian"></i>
						<span>经度</span>
					</div>
					<Input class="width-250" v-model="data.longitude" disabled placeholder="请选择">
					<Button type="primary" slot="append" @click="initMap">选择经纬度</Button>
					</Input>
				</FormItem>
				<FormItem label="纬度">
					<div slot="label">
						<i class="iconfont icon-bitian"></i>
						<span>纬度</span>
					</div>
					<Input class="width-250" v-model="data.latitude" disabled placeholder="请选择"></Input>
				</FormItem>
				<FormItem label="围栏半径" v-if="data.type == 0">
					<div slot="label">
						<i class="iconfont icon-bitian"></i>
						<span>围栏半径</span>
					</div>
					<Input class="width-250" v-model="data.radius" disabled placeholder="请选择"></Input>
				</FormItem>
				<FormItem label="省" v-if="data.type == 2">
					<div slot="label">
						<i class="iconfont icon-bitian"></i>
						<span>省</span>
					</div>
					<Select class="width-250" v-model="data.province" @on-change="sel_province" label-in-value>
						<Option v-for="(item,index) in region" :value="item.name" :tag="index" :key="index">
							{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="市" v-if="data.type == 2">
					<Select class="width-250" v-model="data.city" @on-change="sel_city" label-in-value>
						<Option v-for="(item,index) in city" :value="item.name" :tag="index" :key="index">
							{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="县/区" v-if="data.type == 2">
					<Select class="width-250" v-model="data.area">
						<Option v-for="(item,index) in area" :value="item" :key="index">{{ item }}</Option>
					</Select>
				</FormItem>
				<FormItem label="是否启用">
					<RadioGroup v-model="data.status">
						<Radio :label="1">启用</Radio>
						<Radio :label="0">禁用</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
		</Modal>
		<!-- 选择经纬度 -->
		<Modal title="选择经纬度" :width="90" v-model="map_show" @on-cancel="cancel_map">
			<div class="search-form flex">
				<div class="form-item flex">
					<div style="white-space: nowrap;">地址：</div>
					<input type="text" class="width-250 addr-input" id="map-search" v-model="search_form.addr">
				</div>
				<div class="form-item flex">
					<div>围栏半径：</div>
					<Input class="width-250" :maxlength="5" @on-change="circular_create" v-model="oper_data.radius"
						type='number'></Input>
				</div>
			</div>
			<div id="map"></div>
			<div slot="footer" class="flex" style="justify-content: flex-end;">
				<div style="margin-right: 10px;" v-if="!oper_data.latitude || !oper_data.longitude">您还没有选中一个地理位置</div>
				<div style="margin-right: 10px;" v-else-if="data.type == 0 && oper_data.latitude && oper_data.longitude">选中地址位置的经纬度为：<span
						style="color: #57a3f3;">{{ oper_data.longitude+','+oper_data.latitude }}</span></div>
				<div style="margin-right: 10px;" v-else-if="data.type == 1 && oper_data.latitude && oper_data.longitude">多边形已创建</div>
				<Button @click="cancel_map">取消</Button>
				<Button type="primary" @click="confirm_map">确认</Button>
			</div>
		</Modal>
		<!-- 选择商家 -->
		<SelStore ref="SelStore" @sel-succ="sel_store_succ"></SelStore>
	</div>
</template>
<script type="text/javascript">
	window._AMapSecurityConfig = {
		securityJsCode: '90f1a26c158858b04bf93fa50599caf3',
	}
	import AMapLoader from '@amap/amap-jsapi-loader';
	import region from '@/assets/common/Province_and_ity.json'
	import SelStore from '@/components/common/SelStore.vue'
	import {
		shallowRef
	} from '@vue/reactivity'
	export default {
		components: {
			SelStore
		},
		data() {
			return {
				//围栏创建类型 默认 0：商家围栏 1：设备围栏
				column_type: 0,
				//设备imei号 当column_type为1时，此参数必填
				imei: null,
				//是新增还是修改	1：新增 2：修改
				oper_type: 1,
				//数据
				data: {
					id: null, //围栏ID，仅限于修改时有效
					name: '', //围栏名称
					type: 0, //围栏类型 0：圆形 1：多边形 2：行政自治区
					longitude: '', //经度
					latitude: '', //纬度
					province: '', //省份 当type为2时有效
					city: '', //市	当type为2时有效
					area: '', //区	当type为2时有效
					store_name: null, //所选商家的名称
					store_id: '', //所选商家的ID
					radius: null, //圆的半径
					status: 1 //电子围栏是否启用
				},
				//操作经纬度
				oper_data: {
					longitude: '', //经度
					latitude: '', //纬度
					radius: null //圆的半径
				},
				//地图搜索条件
				search_form: {
					addr: '', //搜索地址
				},
				//地图modal显示
				map_show: false,
				//modal显示
				show: false,
				//城市数据
				region: region,
				//可选市级
				city: [],
				//可选区/县
				area: [],
				//可编辑的圆形对象
				circle: null,
				//圆的标记点
				circular_marker: null,
				//模拟态确认按钮加载状态
				modal_loading: true,
				//多边形对象
				mouseTool: null,
				//多边形显示
				polygon: null
			}
		},
		setup() {
			const map = shallowRef(null);
			return {
				map
			}
		},
		methods: {
			//打开模拟态
			open(imei = null, id = null, column_type = 0, oper_type = 1) {
				//获取围栏数据
				if (id) {
					this.data.id = id
					this.init()
				}
				this.column_type = column_type
				this.imei = imei
				this.oper_type = oper_type
				this.show = true
			},
			//获取围栏数据
			init() {
				let _this = this
				this.requestApi('/adm/get_enclosure_find', {
					id: this.data.id
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.data = res.data
						} else {
							_this.alertErr(res.mgs)
						}
					}
				)
			},
			//初始化地图
			initMap() {
				let _this = this
				this.map_show = true
				AMapLoader.load({
					key: "f0a74a4ce476bbf1ff603389e5ff3105", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					plugins: ['AMap.CircleEditor', 'AMap.AutoComplete',
						'AMap.PlaceSearch', 'AMap.CitySearch', 'AMap.MouseTool'
					], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
				}).then((AMap) => {
					this.AMap = AMap
					this.map = new AMap.Map("map", { //设置地图容器id
						viewMode: "2D", //是否为3D地图模式
						zoom: 12 //初始化地图级别
					});
					//如果是编辑操作则处理
					if(this.data.type == 0 && this.data.latitude && this.data.longitude) {
						this.oper_data.latitude = this.data.latitude
						this.oper_data.longitude = this.data.longitude
						this.oper_data.radius = this.data.radius
					}else if(this.data.type == 1 && this.data.latitude && this.data.longitude) {
						this.oper_data.longitude = this.data.longitude
						this.oper_data.latitude = this.data.latitude
						//创建多边形
						this.polygon_create(this.polygon_str_to_arr({longitude: this.data.longitude,latitude: this.data.latitude}))
					}
					//圆形创建
					if (this.data.type == 0) {
						//要求经纬度都存在
						if (this.oper_data.longitude && this.oper_data.latitude) {
							//创建圆形对象
							this.circular_create()
							//创建标记点
							this.circular_marker_create(this.oper_data.longitude, this.oper_data.latitude)
						}
						//创建点击事件
						this.map.on('click', (res) => {
							this.oper_data.longitude = res.lnglat.lng
							this.oper_data.latitude = res.lnglat.lat
							this.circular_marker_create(res.lnglat.lng, res.lnglat.lat)
							this.circular_create()
						})
					} else if (this.data.type == 1) {
						//创建多边形编辑
						this.polygon_edit()
						//创建点击事件
						this.map.on('click', () => {
							//当多边形已创建时，再次点击则是重新创建
							if(_this.polygon) {
								_this.oper_data.latitude = null
								_this.oper_data.longitude = null
								_this.map.remove(_this.polygon)
								_this.polygon = null
							}
						})
					}
					//输入提示
					var autoOptions = {
						input: "map-search"
					};
					var auto = new AMap.AutoComplete(autoOptions);
					var placeSearch = new AMap.PlaceSearch({
						map: this.map
					}); //构造地点查询类
					auto.on("select", select); //注册监听，当选中某条记录时会触发
					function select(e) {
						placeSearch.setCity(e.poi.adcode);
						placeSearch.search(e.poi.name); //关键字查询查询
					}
					this.map.setFitView()
				})
			},
			//创建多边形编辑
			polygon_edit() {
				let _this = this
				//如果重新绘画则关闭以往
				if (this.mouseTool) this.mouseTool.close()
				//多边形创建
				this.mouseTool = new this.AMap.MouseTool(this.map)
				this.mouseTool.polygon({
					strokeColor: "#FF33FF",
					strokeOpacity: 1,
					strokeWeight: 6,
					fillColor: '#1791fc',
					fillOpacity: 0.4,
					strokeStyle: "solid"
				})
				this.mouseTool.on('draw', function(event) {
					//关闭编辑器
					_this.mouseTool.close(true)
					//重新打开编辑器
					_this.polygon_edit()
					if(event.obj._opts.path.length < 3) return _this.alertErr('绘画点不能小于3个点,请重新绘制	')
					//绘画多边形
					_this.polygon_create(event.obj._opts.path)
					//将绘画好的数据进行赋值
					_this.polygon_data(event.obj._opts.path)
				})
			},
			//创建多边形
			polygon_create(path) {
				this.polygon = new this.AMap.Polygon({
					path: path,
					strokeColor: "#FF33FF",
					strokeOpacity: 1,
					strokeWeight: 6,
					fillColor: '#1791fc',
					fillOpacity: 0.4,
					strokeStyle: "solid"
				});
				this.map.add(this.polygon);
			},
			//处理多边形经纬度数组弄成字符串
			polygon_data(data) {
				let longitude = '' 
				let latitude = ''
				for(let i = 0;i<data.length;i++) {
					if(longitude) {
						longitude += ','+data[i][0]
						latitude += ','+data[i][1]
					}else {
						longitude = data[i][0]
						latitude = data[i][1]
					}
				}
				this.oper_data.longitude = longitude
				this.oper_data.latitude = latitude
			},
			//处理多边形经纬度将字符串弄成数组
			polygon_str_to_arr(data) {
				let longitude = data.longitude.split(',')
				let latitued = data.latitude.split(',')
				let arr = []
				for(let i = 0;i<longitude.length;i++) {
					arr.push([longitude[i],latitued[i]])
				}
				return arr
			},
			//选择省份触发
			sel_province(row) {
				this.city = this.region[row.tag].city
				this.data.city = null
			},
			//选择市触发
			sel_city(row) {
				if (row) {
					this.area = this.city[row.tag].county
				} else {
					this.area = []
				}
				this.data.area = null
			},
			//选择商家回调
			sel_store_succ(row) {
				this.data.store_id = row.store_id
				this.data.store_name = row.name
			},
			//圆的创建
			circular_create() {
				if (this.oper_data.radius === null) return false
				//如果已有圆形实例则销毁
				if (this.circle) {
					this.ccircleEditor.close()
					this.map.remove(this.circle)
				}
				//创建圆形实例
				this.circle = new this.AMap.Circle({
					center: new this.AMap.LngLat(this.oper_data.longitude, this.oper_data.latitude), // 圆心位置
					radius: this.oper_data.radius, // 圆半径
					fillColor: '#00B2D5', // 圆形填充颜色
					strokeColor: '#fff', // 描边颜色
					strokeWeight: 2, // 描边宽度
					draggable: true
				});
				//添加圆形实例
				this.map.add(this.circle)
				//将视野拉到合适的位置
				this.map.setFitView([this.circle])
				//创建圆形编辑器
				this.ccircleEditor = new this.AMap.CircleEditor(this.map, this.circle)
				//监听圆的半径改变
				this.ccircleEditor.on('adjust', (res) => {
					this.oper_data.radius = res.radius
					this.map.setFitView()
				})
				//监听圆的移动
				this.ccircleEditor.on('move', (res) => {
					this.oper_data.latitude = res.lnglat.lat
					this.oper_data.longitude = res.lnglat.lng
					this.circular_marker_create(res.lnglat.lng, res.lnglat.lat)
				})
				//开启编辑
				this.ccircleEditor.open()
			},
			//取消地图模拟态
			cancel_map() {
				this.map_show = false
				this.oper_data = {
					longitude: '', //经度
					latitude: '', //纬度
					radius: null //圆的半径
				}
				this.search_form.addr = null
			},
			//确认选择地图
			confirm_map() {
				if(!this.oper_data.latitude || !this.oper_data.longitude) return this.alertErr('请绘画电子围栏')
				this.map_show = false
				this.data.latitude = this.oper_data.latitude
				this.data.longitude = this.oper_data.longitude
				this.data.radius = this.oper_data.radius
				this.oper_data.latitude = null
				this.oper_data.latitude = null
				this.oper_data.radius = null
				this.circular_marker = null
			},
			//创建圆的标记点
			circular_marker_create(lng, lat) {
				//如果已有标记点则直接修改标记点位置
				if (
					this.circular_marker) {
					this.circular_marker.setPosition(new this.AMap.LngLat(lng, lat))
				} else {
					//如果没有则创建标记点
					this.circular_marker = new this.AMap.Marker({
						position: new this.AMap.LngLat(lng, lat) // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
					});
					this.map.add(this.circular_marker)
				}
				this.map.setFitView()
			},
			//模拟态确认按钮加载状态改变
			loading_change() {
				this.modal_loading = false
				this.$nextTick(() => {
					this.modal_loading = true
				})
			},
			//恢复默认值
			recovery_default() {
				this.data = {
					id: null, //围栏ID，仅限于修改时有效
					name: '', //围栏名称
					type: 0, //围栏类型 0：圆形 1：多边形 2：行政自治区
					longitude: '', //经度
					latitude: '', //纬度
					province: '', //省份 当type为2时有效
					city: '', //市	当type为2时有效
					area: '', //区	当type为2时有效
					store_name: null, //所选商家的名称
					store_id: '', //所选商家的ID
					radius: null, //圆的半径
					status: 1 //电子围栏是否启用
				}
			},
			//确认提交
			confirm_modal() {
				let _this = this
				let data = this.data
				console.log(data.store_id, this.column_type)
				if (!data.store_id && this.column_type == 0) {
					this.loading_change()
					return this.alertErr('请选择商家')
				}
				if (!data.name) {
					this.loading_change()
					return this.alertErr('请输入围栏名称')
				}
				if ((!data.longitude || !data.latitude) && (!data.type == 0 || data.type == 1)) {
					this.loading_change()
					return this.alertErr('请选择经纬度')
				}
				if (!data.radius && data.type == 0) {
					this.loading_change()
					return this.alertErr('请输入围栏半径')
				}
				data.column_type = this.column_type
				data.oper_type = this.oper_type
				if (data.oper_type) {
					data.imei = this.imei
				}
				this.requestApi('/adm/oper_enclosure', {
					data
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.show = false
							_this.alertSucc(res.msg)
							_this.recovery_default()
							_this.$emit('oper-succ', res)
						} else {
							_this.loading_change()
							_this.alertErr(res.msg)
						}
					}
				).catch(
					(res) => {
						_this.loading_change()
						_this.alertErr(res.msg)
					}
				)
			}
		}
	}
</script>

<style scoped="scoped">
	#map {
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 500px;
	}

	.width-250 {
		width: 250px;
	}

	.icon-bitian {
		color: red;
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.form-item {
		margin-right: 20px;
	}

	.search-form {
		margin-bottom: 10px;
	}

	.addr-input {
		display: inline-block;
		height: 32px;
		line-height: 1.5;
		padding: 4px 7px;
		font-size: 14px;
		border: 1px solid #dcdee2;
		border-radius: 4px;
		color: #515a6e;
		background-color: #fff;
		background-image: none;
		position: relative;
		cursor: text;
		transition: border .2s ease-in-out, background .2s ease-in-out, box-shadow .2s ease-in-out;
	}

	.addr-input:focus {
		border-color: #57a3f3;
		outline: 0;
		box-shadow: 0 0 0 2px rgb(45 140 240 / 20%);
	}
	
</style>
