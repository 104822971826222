<template>
	<div>
		<Button type="primary" style="margin-bottom: 10px;"
			@click="$refs['ElectronicFenceEdit'].open(device_imei)">新增</Button>
		<Table :data="fence_data" :columns="fence_columns" border :loading="table_loading">
			<template slot="status" slot-scope="{row}">
				<Tag :color="row.status == 1?'success':'error'">{{ row.status == 1?'开启':'关闭' }}</Tag>
			</template>
			<template slot="operation" slot-scope="{row}">
				<Button type="primary" style="margin-right: 10px;"
					@click="$refs['ElectronicFenceEdit'].open(device_imei,row.id,0,0)">修改</Button>
				<Button type="error" @click="del_fence(row.id)">删除</Button>
			</template>
		</Table>
		<Page style="text-align: right;margin-top: 10px;" v-model="search.page" :page-size="search.pageSize"
			:total="search.total" :page-size-opts="[10,15,20]" show-sizer show-total @on-change="page_change"
			@on-page-size-change="page_size_change"></Page>
		<!-- 新增电子围栏 -->
		<ElectronicFenceEdit ref="ElectronicFenceEdit" @oper-succ="init"></ElectronicFenceEdit>
	</div>
</template>

<script>
	import ElectronicFenceEdit from '@/components/common/electronic_fence';
	export default {
		name: 'ElectronicFence',
		components: {
			ElectronicFenceEdit
		},
		data() {
			return {
				table_loading: false, // 表格加载状态
				search: {
					page: 1, //当前页码
					total: 0, //数据总数
					pageSize: 10 //每页条数
				},
				//电子围栏数据
				fence_data: [],
				//电子围栏类型
				fence_class_arr: ['圆形', '多边形', '行政区域'],
				//电子围栏表头
				fence_columns: [{
						title: '序号',
						render: (h, param) => {
							return h('div', param.index + 1)
						},
						align: 'center',
						width: 100
					},
					{
						title: '围栏名称',
						key: 'name',
						align: 'center'
					},
					{
						title: '围栏类型',
						align: 'center',
						render: (h, param) => {
							return h('div', this.fence_class_arr[param.row.type])
						}
					},
					{
						title: '是否启用',
						align: 'center',
						slot: 'status'
					},
					{
						title: '操作',
						slot: 'operation',
						align: 'center'
					},
				],
			}
		},
		props: {
			path: {
				type: String,
				default: '/adm'
			},
			device_imei: {
				type: String,
				default: ''
			},
			device_m_id: {
				type: [Number, String],
				default: 0
			}
		},
		methods: {
			//获取电子围栏数据
			init() {
				var _this = this;
				let data = this.search
				data.imei = this.device_imei
				this.table_loading = true
				this.requestApi(this.path + '/get_fance_data', {
					data
				}).then((res) => {
					_this.table_loading = false
					if (res.code == 200) {
						_this.fence_data = res.data.data
						_this.search.total = res.data.total
					} else {
						_this.alertErr(res.msg)
					}
				});
			},
			//删除电子围栏
			del_fence(id) {
				var _this = this;
				this.$Modal.confirm({
					title: '温馨提示',
					content: '确定删除该电子围栏吗？',
					onOk: () => {
						_this.requestApi(this.path + '/del_device_fence', {
							id
						}).then(
							(res) => {
								if (res.code == 200) {
									_this.alertSucc(res.msg)
								} else {
									_this.alertErr(res.msg)
								}
								_this.init()
							}
						)
					}
				})
			},
			// 切换页码
			page_change(page) {
				this.search.page = page;
				this.init();
			},
			// 切换每页条数
			page_size_change(pageSize) {
				this.search.pageSize = pageSize;
				this.init();
			}
		}
	}
</script>

<style scoped>

</style>
